@mixin scrol-site($color: $c1, $width:5px) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: #ddd; 
  }
  &::-webkit-scrollbar-thumb {
    background: $color;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($color, 10%)
  }
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @include scrol-site(#4E0E36);
  font-family: "Assistant, sans-serif";
}
input{
  outline: none;
}
button{
  box-shadow: none;
 
}


.receipt{
  h1, p, strong, div, td, th, table, span, u, b {
    font-family: "Assistant";
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  
  td, th {
    border: 1px solid #000000;
    text-align: right;
    padding: 8px;
    line-height: 150%;
  }
  
}